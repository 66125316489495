import React from "react";
import { graphql } from 'gatsby'
import loadable from "@loadable/component";
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import Layout from "../components/layout";
import SEO from "../components/seo"
const ContactIntro = loadable(() => import("../components/ContactIntro/ContactIntro"));
const ContactCard = loadable(() => import("../components/ContactCard/ContactCard"));
const PatternBanner = loadable(() => import("../components/PatternBanner/PatternBanner"));
const FooterContactMobile = loadable(() => import("../components/FooterContactMobile/FooterContactMobile"));

const Contact = ({ data }, props) => {
  const PageData = data?.strapiPage
  const Offices = data?.allStrapiOffice.edges
  const themestyle = data?.strapiThemeConfig?.theme_style;

  console.log("Contact", PageData)

  return (
    <Layout popularSearch={PageData?.select_popular_search?.title} customClass={PageData?.custom_css_classname ? PageData?.custom_css_classname : ""}>
      <div className="layout-padding-top contact-page-wrap">
        <div className="office-landing-page-wrapper">
          <Container>
            <Row>
              <Col lg={process.env.GATSBY_CONTACT_GRID_THREE === "true" ? 6 : 6} md={process.env.GATSBY_CONTACT_GRID_THREE === "true" ? 12 : 6}>
                <ContactIntro {...PageData.banner} pagename={PageData.title} />
              </Col>
            </Row>
          </Container>

          {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
            return (
              <>
                {module.strapi_component === "page-modules.global-module" && module.select_module === "offices" &&
                  <div className="office-card-grid">
                    <Container>
                      <Row>
                        {Offices && Offices.map(({ node }, index) => {
                          return (
                            <Col lg={process.env.GATSBY_CONTACT_GRID_THREE === "true" ? 4 : 6} md={process.env.GATSBY_CONTACT_GRID_THREE === "true" ? 6 : 6}>
                              <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={index * 100} offset={10}>
                                <ContactCard {...node} />
                              </ScrollAnimation>
                            </Col>
                          )
                        })}
                      </Row>
                    </Container>
                  </div>
                }
                {module.strapi_component === "page-modules.text-module" && <PatternBanner
                    tag={module.layout === "background_secondary_color" ? "blue" : module.layout === "background_primary_color" ? "brown" : ''}
                    {...module} themestyle={themestyle}
                />}
              </>
            )
          })}
        </div>
      </div>

      <div className="d-md-none">
        <FooterContactMobile />
      </div>
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
  )
}

export default Contact

export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
              ...GlobalModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_TEXT_MODULE {
              ...TextModuleFragment
            }
        }
    }
    
    strapiThemeConfig {
      theme_style
    }

    allStrapiOffice(filter: {publish: {eq: true}}) {
      edges {
        node {
          title
          address
          slug
          phone
          email
          sales_email
          sales_phone
          lettings_email
          lettings_phone
          image {
            alternativeText
            url
          }
          imagetransforms {
            image_Transforms
          }
          strapi_id
        }
      }
    }
  }
`